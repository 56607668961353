export default class homepage {
    constructor() {

        const  webSearch = document.getElementById('SearchByWebsite');
        const  catalogueSearch = document.getElementById('SearchByCatalog');
        const checkSlick = document.getElementById('checkThisOut');

        if (webSearch) {
            this.webSearch = webSearch;
            this.setWebSearch();
        }
        if (catalogueSearch) {
            this.catalogueSearch = catalogueSearch;
            this.setCatalogueSearch();
        }
        if (checkSlick) {
            this.setSlick();
        }
    }
    setWebSearch() {
        this.webSearch.addEventListener('click', () => {
            const searchWebsite = document.getElementById('website-search');
            const catalogSearch = document.getElementById('Catalog-search');
            catalogSearch.classList.add('d-none');
            searchWebsite.classList.remove('d-none');
            console.log("Hide catalogue");
        });
    }
    setCatalogueSearch() {
        this.catalogueSearch.addEventListener('click', () => {
            const searchWebsite = document.getElementById('website-search');
            const catalogSearch = document.getElementById('Catalog-search');
            catalogSearch.classList.remove('d-none');
            searchWebsite.classList.add('d-none');
            console.log("Hide web");
        });
    }
    setSlick() {
        console.log("slicks for homepage");
        $('.twoSlick').slick({
            autoplay: false,
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 2,
            speed: 1000,
            responsive: [
                {
                    breakpoint: 1012,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '120px',
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '60px',
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1
                    }
                }
            ]
        });
        $(".centerSlick").slick({
            arrows: true,
            autoplay: true,
            centerMode: true,
            centerPadding: "80px",
            slidesToShow: 1,
            prevArrow:
                "<button type='button' class='slcol-slick-prev'><svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'><path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path></svg><span class=\"visually-hidden\">Previous</span></button>",
            nextArrow:
                "<button type='button' class='slcol-slick-next'><svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 24 24'><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path></svg><span class=\"visually-hidden\">Next</span></button>",
            speed: 1000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {}
                },
                {
                    breakpoint: 992,
                    settings: {}
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerPadding: "40px",
                        dots: true,
                    }
                }
            ]
        });
    }

}

