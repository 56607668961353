'use strict';

// Packages
import 'jquery';
import '@popperjs/core';
import 'bootstrap';
import 'slick-carousel';
import Fancybox from '@fancyapps/ui';

import homepage from './components/homepage';
import movies from './components/movies';
import music from './components/music';
import ViridanPhotoTour from './components/viridian-photo-tour';
import borrowAnother from './components/borrow-another-library';
import reconsideration from './components/reconsideration';
import heavyLoad from './components/heavy-load';
import connectNewsletter from './components/connect-issues';
import printThreed from './components/3-d-print';
import comingSoonMonth from './components/coming-soon';
import goLive from './components/event-scheduler';
import mapAll from './components/google-maps-locations';
import hashOpen from './components/hash-open';
import modalOpen from './components/modal-open';
import purchaseSuggestion from './components/suggest-a-purchase';
import donation from './components/donations';
import chalkthewalk from './components/chalk-the-walk';
import boxesFancy from './components/fancybox';
import contactHeadLibrarian from './components/wizard_contact';

$(function() {
    new homepage();
    new movies();
    new music();
    new ViridanPhotoTour();
    new borrowAnother();
    new reconsideration();
    new heavyLoad();
    new connectNewsletter();
    new printThreed();
    new comingSoonMonth();
    new goLive();
	new mapAll();
    new hashOpen();
    new modalOpen();
    new purchaseSuggestion();
	new donation();
    new chalkthewalk();
    new boxesFancy();
    new contactHeadLibrarian();
});