export default class contactHeadLibrarian {
    constructor() {
        const requestHeadLibrarian = document.getElementById('WizardContact');
        if (requestHeadLibrarian) {
            this.questionOWLS();
        }
    }
    questionOWLS() {

        console.log("Contact Head Librarian");

        //Topic checker!
        $("#wizard_topic").on("change", function(){
            var value = $("#wizard_topic").val();
            console.log(value + " Chosen");
            if (value === "O.W.L. Camp"){
                $("#OwlTopic").removeClass("d-none");
            } else {
                $("#OwlTopic").addClass("d-none");
                $("#CampAppHelp").addClass("d-none");
                $("#CampShirtComments").addClass("d-none");
            }
        });//end topic choice

        $('input[type=radio][name="formfields[wiz_owltopichelp]"]').on('change',function() {
            var radiochoice = $(this).val();
            console.log(radiochoice);
            if ($(this).attr("id") === "wiz_helpmanapp") {
                $("#CampAppHelp").removeClass("d-none");
                $("#CampShirtComments").addClass("d-none");
            } else if ($(this).attr("id") === "wiz_helpshirt") {
                $("#CampAppHelp").addClass("d-none");
                $("#CampShirtComments").removeClass("d-none");
            }else {
                $("#CampAppHelp").addClass("d-none");
                $("#CampShirtComments").addClass("d-none");
            }
        });

    }
}

